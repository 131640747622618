<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
ol ol {
  padding-left: 1em;
  list-style-type: lower-latin;
}
ol ol ol {
  padding-left: 1.5em;
  list-style-type: lower-roman;
}
ol ol ol ol {
  padding-left: 2em;
  list-style-type: upper-latin;
}
ol ol ol ol ol {
  padding-left: 2.5em;
  list-style-type: upper-roman;
}
* {
  word-wrap: break-word;
  word-break: break-word;
}
</style>
